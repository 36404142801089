<script setup>
const showArrow = ref(false)
const sreenHeight = ref(0)

function onScroll() {
  sreenHeight.value = window.innerHeight - 100
  showArrow.value = window.top.scrollY > sreenHeight.value
}

function scrollToTop() {
  window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
}

window.addEventListener('scroll', onScroll)
onScroll()
</script>

<template>
  <v-footer app class="bg-grey-lighten-2 d-flex justify-center text-center text-grey">
    <span>&copy; {{ new Date().getFullYear() }} INDEPENDENT ELECTRICAL CONTRACTORS. ALL RIGHTS RESERVED</span>
    <Transition name="fade">
      <div v-if="showArrow">
        <v-tooltip text="Top" location="top">
          <template #activator="{ props }">
            <v-btn size="large" variant="flat" icon="mdi-arrow-up-bold" class="pa-0" color="primary" style="position: absolute; bottom: 1rem; right: 0.5rem; z-index: 2; border: 2px solid white" alt="Top" v-bind="props" @click="scrollToTop"></v-btn>
          </template>
        </v-tooltip>
      </div>
    </Transition>
  </v-footer>
</template>
